import { render, staticRenderFns } from "./MarketingData.vue?vue&type=template&id=070be938&scoped=true"
import script from "./MarketingData.vue?vue&type=script&lang=js"
export * from "./MarketingData.vue?vue&type=script&lang=js"
import style0 from "./MarketingData.vue?vue&type=style&index=0&id=070be938&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070be938",
  null
  
)

export default component.exports